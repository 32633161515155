const horror = [
  {
    name: "ta",
    src: require("../images/T1_low.jpg"),
    thumbnail: require("../images/T1_low.jpg"),
    lowRes: require("../images/T1.jpg"),
    highRes: require("../images/T1_hd.jpg"),
    title: "Sigbin",
    description:
      "Sigbin Goat creature that walks backwards with its head lowered between its hind legs",
    link: "/projects/aswang",
  },
  {
    name: "tc",
    src: require("../images/T3_low.jpg"),
    thumbnail: require("../images/T3_low.jpg"),
    lowRes: require("../images/T3.jpg"),
    highRes: require("../images/T3_hd.jpg"),
    title: "Tiktik",
    description:
      "Tiktik Creature that has a long tongue that suck babies from pregnant wombs",
    link: "/projects/aswang",
  },
  {
    name: "tb",
    src: require("../images/T2_low.jpg"),
    thumbnail: require("../images/T2_low.jpg"),
    lowRes: require("../images/T2.jpg"),
    highRes: require("../images/T2_hd.jpg"),
    title: "Kapre",
    description:
      "Kapre Tree dwelling giant who occasionally messes with civillians",
    link: "/projects/aswang",
  },
  {
    name: "td",
    src: require("../images/T4_low.jpg"),
    thumbnail: require("../images/T4_low.jpg"),
    lowRes: require("../images/T4.jpg"),
    highRes: require("../images/T4_hd.jpg"),
    title: "Manananggal",
    description:
      "Manananggal Aswang lady that looks normal until she bisects from her waist to take flight and hunt",
    link: "/projects/aswang",
  },
];

const fish = [
  {
    name: "fa",
    src: require("../images/projects/fish/Chapter2final.jpg"),
    thumbnail: require("../images/projects/fish/Chapter2final_low.jpg"),
    lowRes: require("../images/projects/fish/Chapter2final.jpg"),
    highRes: require("../images/projects/fish/Chapter2final_hd.jpg"),
    title: "Chapter2final",
    // description: "taco"
    description: "",
    link: "/projects/five-stages-of-immigration",
  },
  {
    name: "fb",
    src: require("../images/projects/fish/Chapter5final.jpg"),
    thumbnail: require("../images/projects/fish/Chapter5final_low.jpg"),
    lowRes: require("../images/projects/fish/Chapter5final.jpg"),
    highRes: require("../images/projects/fish/Chapter5final_hd.jpg"),
    title: "Reverse Culture Shock",
    description:
      'Depicts the "Reverse Culture Shock" stage of immigration from my project',
    link: "/projects/five-stages-of-immigration",
  },
  {
    name: "fc",
    src: require("../images/projects/fish/Chapter3final.jpg"),
    thumbnail: require("../images/projects/fish/Chapter3final_low.jpg"),
    lowRes: require("../images/projects/fish/Chapter3final.jpg"),
    highRes: require("../images/projects/fish/Chapter3final_hd.jpg"),
    title: "Chapter3final",
    // description: "taco"
    description: "",
    link: "/projects/five-stages-of-immigration",
  },
];

const featherfell = [
  {
    name: "ffa",
    src: require("../images/projects/featherfell/trailer1final.jpg"),
    thumbnail: require("../images/projects/featherfell/trailer1final_low.jpg"),
    lowRes: require("../images/projects/featherfell/trailer1final.jpg"),
    highRes: require("../images/projects/featherfell/trailer1final_hd.jpg"),
    title: "Trailer Scene 1",
    // description: "taco"
    description: "Scene 1 for featherfell trailer",
    link: "/projects/featherfell",
  },
  {
    name: "ffb",
    src: require("../images/projects/featherfell/trailer2final.jpg"),
    thumbnail: require("../images/projects/featherfell/trailer2final_low.jpg"),
    lowRes: require("../images/projects/featherfell/trailer2final.jpg"),
    highRes: require("../images/projects/featherfell/trailer2final_hd.jpg"),
    title: "Trailer Scene 2",
    description: "Scene 2 for featherfell trailer",
    link: "/projects/featherfell",
  },
  {
    name: "ffc",
    src: require("../images/projects/featherfell/trailer5final.jpg"),
    thumbnail: require("../images/projects/featherfell/trailer5final_low.jpg"),
    lowRes: require("../images/projects/featherfell/trailer5final.jpg"),
    highRes: require("../images/projects/featherfell/trailer5final_hd.jpg"),
    title: "Trailer Scene 5",
    description: "Parallax shot for featherfell trailer",
    link: "/projects/featherfell",
  },
  {
    name: "ffd",
    src: require("../images/projects/featherfell/artbookfinal.jpg"),
    thumbnail: require("../images/projects/featherfell/artbookfinal_low.jpg"),
    lowRes: require("../images/projects/featherfell/artbookfinal.jpg"),
    highRes: require("../images/projects/featherfell/artbookfinal_hd.jpg"),
    title: "Featherfell Artbook Cover",
    description: "Book cover mockup for Featherfell project",
    link: "/projects/featherfell",
  },
  {
    name: "ffe",
    src: require("../images/projects/featherfell/gamecover_no_border.jpg"),
    thumbnail: require("../images/projects/featherfell/gamecover_no_border_low.jpg"),
    lowRes: require("../images/projects/featherfell/gamecover_no_border.jpg"),
    highRes: require("../images/projects/featherfell/gamecover_no_border_hd.jpg"),
    title: "Featherfell Game Cover",
    description: "Game cover mockup for Featherfell project",
    link: "/projects/featherfell",
  },
];

const bikbakbok = {
  name: "bbb",
  src: require("../images/bikbakbok_low.jpg"),
  thumbnail: require("../images/bikbakbok_low.jpg"),
  lowRes: require("../images/bikbakbok.jpg"),
  highRes: require("../images/bikbakbok_hd.jpg"),
  title: "Bik Bak Bok",
  description:
    "A three-headed chicken consisting of Bik, the goofy sidekick; Bak, the leader type; and Bok, the menace. The three brothers often fight with each other but when it matters the most they always pull through from any situation together.",
};
const lipad = {
  name: "va",
  src: require("../images/lipad_low.jpg"),
  thumbnail: require("../images/lipad_low.jpg"),
  lowRes: require("../images/lipad.jpg"),
  highRes: require("../images/lipad_hd.jpg"),
  title: "Lipad",
  description:
    "A piece that captures the nostalgia and colours inspired by the sunsets and rice fields in the Philippines.",
};
const krillia = {
  name: "mm",
  src: require("../images/krillia_low.jpg"),
  thumbnail: require("../images/krillia_low.jpg"),
  lowRes: require("../images/krillia.jpg"),
  highRes: require("../images/krillia_hd.jpg"),
  title: "Krillia",
  description: "A character concept inspired by penguin and streetwear",
};
const stjoan = {
  name: "l",
  src: require("../images/st_joan_low.jpg"),
  thumbnail: require("../images/st_joan_low.jpg"),
  lowRes: require("../images/st_joan.jpg"),
  highRes: require("../images/st_joan_hd.jpg"),
  title: "Saint Joan",
  description: "A poster for the Shaw festival’s Saint Joan play",
};
const noelle = {
  name: "hh",
  src: require("../images/noelle_low.jpg"),
  thumbnail: require("../images/noelle_low.jpg"),
  lowRes: require("../images/noelle.jpg"),
  highRes: require("../images/noelle_hd.jpg"),
  title: "Noelle",
  description: "Splashart of  Noelle from Genshin Impact",
};
const pirate = {
  name: "c",
  src: require("../images/pirate_low.jpg"),
  thumbnail: require("../images/pirate_low.jpg"),
  lowRes: require("../images/pirate.jpg"),
  highRes: require("../images/pirate_hd.jpg"),
  title: "Pirate",
  description:
    "A piece that focuses on capturing the warmness of yellow while juxtaposing the ragged lifestyle of pirates",
};
const hiona = {
  name: "hiona",
  src: require("../images/JB_Gallego_ONLY_low.jpg"),
  thumbnail: require("../images/JB_Gallego_ONLY_low.jpg"),
  lowRes: require("../images/JB_Gallego_ONLY.jpg"),
  highRes: require("../images/JB_Gallego_ONLY_hd.jpg"),
  title: "Hiona",
  description: "Movie poster featuring my girlfriend's cat",
};
const aigis = {
  name: "aigis",
  src: require("../images/aigis_low.jpg"),
  thumbnail: require("../images/aigis_low.jpg"),
  lowRes: require("../images/aigis.jpg"),
  highRes: require("../images/aigis_hd.jpg"),
  title: "Aigis Fanart",
  description: "",
};
const malenia = {
  name: "malenia",
  src: require("../images/malenia_low.jpg"),
  thumbnail: require("../images/malenia_low.jpg"),
  lowRes: require("../images/malenia.jpg"),
  highRes: require("../images/malenia_hd.jpg"),
  title: "Malenia Fanart",
  description: "Work in progress 🚧",
};

const sekiro = {
  name: "seki",
  src: require("../images/sekiro_low.jpg"),
  thumbnail: require("../images/sekiro_low.jpg"),
  lowRes: require("../images/sekiro.jpg"),
  highRes: require("../images/sekiro_hd.jpg"),
  title: "Sekiro",
  description: "Fanart of Genichiro's last fight",
};

// const karlach = {
//   name: "karlach",
//   src: require("../images/karlach_low.jpg"),
//   thumbnail: require("../images/karlach_low.jpg"),
//   lowRes: require("../images/karlach.jpg"),
//   highRes: require("../images/karlach_hd.jpg"),
//   title: "Karlach",
//   description: "Fanart of Karlach",
// };

const shart = {
  name: "shart",
  src: require("../images/shart_low.jpg"),
  thumbnail: require("../images/shart_low.jpg"),
  lowRes: require("../images/shart.jpg"),
  highRes: require("../images/shart_hd.jpg"),
  title: "Shadow heart",
  description: "Fanart of Shadow heart from BG3",
};

const crashlanding = {
  name: "crashlanding",
  src: require("../images/crashlanding_low.jpg"),
  thumbnail: require("../images/crashlanding_low.jpg"),
  lowRes: require("../images/crashlanding.jpg"),
  highRes: require("../images/crashlanding_hd.jpg"),
  title: "Crash Landing Book Cover",
  description:
    'Book Cover illustration for YA novel "Crash Landing" written by Li Charmaine Anne, published by Annick Press',
};

const cats = [
  {
    name: "",
    src: require("../images/projects/cats/Two_of_Them_low.jpg"),
    thumbnail: require("../images/projects/cats/Two_of_Them_low.jpg"),
    lowRes: require("../images/projects/cats/Two_of_Them.jpg"),
    highRes: require("../images/projects/cats/Two_of_Them_hd.jpg"),
    title: "Two of Them",
    description: "3x3 acrylic on canvas",
  },
  {
    name: "",
    src: require("../images/projects/cats/Princess_Hiona_low.jpg"),
    thumbnail: require("../images/projects/cats/Princess_Hiona_low.jpg"),
    lowRes: require("../images/projects/cats/Princess_Hiona.jpg"),
    highRes: require("../images/projects/cats/Princess_Hiona_hd.jpg"),
    title: "Princess Hiona",
    description: "3x3 acrylic on canvas",
  },
  {
    name: "",
    src: require("../images/projects/cats/Link_low.jpg"),
    thumbnail: require("../images/projects/cats/Link_low.jpg"),
    lowRes: require("../images/projects/cats/Link.jpg"),
    highRes: require("../images/projects/cats/Link_hd.jpg"),
    title: "Link",
    description: "3x3 acrylic on canvas",
  },
  {
    name: "",
    src: require("../images/projects/cats/Hiona_low.jpg"),
    thumbnail: require("../images/projects/cats/Hiona_low.jpg"),
    lowRes: require("../images/projects/cats/Hiona.jpg"),
    highRes: require("../images/projects/cats/Hiona_hd.jpg"),
    title: "Hiona",
    description: "3x3 acrylic on canvas",
  },
  {
    name: "",
    src: require("../images/projects/cats/Cereal_Cat_low.jpg"),
    thumbnail: require("../images/projects/cats/Cereal_Cat_low.jpg"),
    lowRes: require("../images/projects/cats/Cereal_Cat.jpg"),
    highRes: require("../images/projects/cats/Cereal_Cat_hd.jpg"),
    title: "Cereal Cat",
    description: "3x3 acrylic on canvas",
  },
  {
    name: "",
    src: require("../images/projects/cats/Cloud_and_Link_low.jpg"),
    thumbnail: require("../images/projects/cats/Cloud_and_Link_low.jpg"),
    lowRes: require("../images/projects/cats/Cloud_and_Link.jpg"),
    highRes: require("../images/projects/cats/Cloud_and_Link_hd.jpg"),
    title: "Cloud and Link",
    description: "3x3 acrylic on canvas",
  },
  {
    name: "",
    src: require("../images/projects/cats/Cloud_low.jpg"),
    thumbnail: require("../images/projects/cats/Cloud_low.jpg"),
    lowRes: require("../images/projects/cats/Cloud.jpg"),
    highRes: require("../images/projects/cats/Cloud_hd.jpg"),
    title: "Cloud",
    description: "3x3 acrylic on canvas",
  },
  {
    name: "",
    src: require("../images/projects/cats/Fat_Tony_low.jpg"),
    thumbnail: require("../images/projects/cats/Fat_Tony_low.jpg"),
    lowRes: require("../images/projects/cats/Fat_Tony.jpg"),
    highRes: require("../images/projects/cats/Fat_Tony_hd.jpg"),
    title: "Fat Tony",
    description: "3x3 acrylic on canvas",
  },
  {
    name: "",
    src: require("../images/projects/cats/cat_commission_low.jpg"),
    thumbnail: require("../images/projects/cats/cat_commission_low.jpg"),
    lowRes: require("../images/projects/cats/cat_commission.jpg"),
    highRes: require("../images/projects/cats/cat_commission_hd.jpg"),
    title: "Cat Commission",
    description: "3x3 acrylic on canvas",
  },
  {
    name: "",
    src: require("../images/projects/cats/Cat_Commission_2_low.jpg"),
    thumbnail: require("../images/projects/cats/Cat_Commission_2_low.jpg"),
    lowRes: require("../images/projects/cats/Cat_Commission_2.jpg"),
    highRes: require("../images/projects/cats/Cat_Commission_2_hd.jpg"),
    title: "Cat Commission 2",
    description: "3x3 acrylic on canvas",
  },
];

const defaultImages = [
  shart,
  sekiro,
  aigis,
  featherfell[3],
  featherfell[4],
  malenia,
  fish[1],
  featherfell[1],
  horror[1],
  bikbakbok,
  pirate,
  crashlanding,
  hiona,
  lipad,
  krillia,
  stjoan,
  noelle,
];

export { defaultImages, cats };
