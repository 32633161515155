// @ts-nocheck
import { useColorMode, IconButton } from "@chakra-ui/react";
import {BsFillMoonFill, BsFillSunFill} from "react-icons/bs";


import React from "react";
const ThemeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <IconButton
      onClick={toggleColorMode}
      aria-label="Search database"
      icon={colorMode === "light" ? <BsFillMoonFill /> : <BsFillSunFill />}
    />
  );
};

export default ThemeToggle;
