import { useEffect, useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

import "./Modal.css";
import React from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";

const Modal = ({ isFirst, isLast, onBack, onNext, selected, deselect }) => {
  const [image, setImage] = useState(selected?.lowRes || "");
  const [fullscreen, setFullscreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        deselect();
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [deselect]);

  useEffect(() => {
    if (!selected) {
      return;
    }
    setImage(selected.thumbnail);

    const img = new Image();
    img.src = selected.highRes;

    img.onload = () => {
      setIsLoading(false);
      setImage(selected.highRes);
    };
  }, [selected]);

  useEffect(() => {
    if (!selected) {
      document.body.style.height = "100%";
      document.body.style.overflow = "auto";
      return;
    }

    document.body.style.overflow = "hidden";
  }, [selected]);

  const handleNext = () => {
    onNext();
  };
  const bg = useColorModeValue('white', 'rgba(0, 0, 0, 0.808)')

  return (
    <Box mb={4} bg={bg} id="modal" className={`modal ${!!selected ? "modal-open" : ""}`}>
      {selected ? (
<>
          <div className="modal-content">
            <div
              style={{
                backgroundImage: `url(${image})`,
              }}
              onClick={() => setFullscreen((value) => !value)}
              className={`modal-img
                ${isLoading ? ".modal-loading" : ""}
                ${fullscreen ? "fullscreen" : ""}`
              }
            />
            {
              fullscreen? <img id="fullscreen-img" src={image} alt=""onClick={() => setFullscreen((value) => !value)} /> :null
            }
            <div
              className={`modal-text ${fullscreen ? "fullscreen" : ""}`}
              style={{
                marginTop: "20px",
                textAlign: "left",
              }}
            >
              <h2>
                <b>{selected?.title}</b>
                <hr
                  style={{
                    width: "100px",
                    margin: 0,
                    marginTop: "10px",
                  }}
                />
              </h2>
              <p style={{ marginTop: "30px" }}>{selected?.description}</p>
              {selected?.link ? (
                <a
                  href={selected.link}
                  id="project-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>Go to project</span>
                  <MdOutlineKeyboardArrowRight />
                </a>
              ) : null}
            </div>
          </div>
          <RiCloseFill
            style={{
              position: "absolute",
              right: "20px",
              top: "20px",
              fontSize: "2em",
              cursor: "pointer",
            }}
            onClick={deselect}
          />
          <div className="arrow-buttons-container">
            <MdKeyboardArrowLeft
              className="left-arrow-button"
              onClick={onBack}
              style={isFirst ? { cursor:  "not-allowed", opacity: .4 } :{ cursor:  "pointer" }}
            />
            <MdKeyboardArrowRight
              className="left-arrow-button"
              onClick={handleNext}
              style={isLast ? { cursor:  "not-allowed", opacity: .4 } :{ cursor:  "pointer" }}

            />
          </div>
        </>
      ) : null}
    </Box>
  );
};

export default Modal;
