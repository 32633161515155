import "../GalleryDesktop.css";
import "../GalleryMobile.css";
import "../GalleryShared.css";

import Thumbnail from './Thumbnail.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import React from "react";

import './Projects.css'

import {projectImages} from './projectImages'
import { useNavigate } from "react-router-dom";

function Projects() {
  const navigate = useNavigate();

  return (
    <>
      <div className="projects-gallery">
        <Thumbnail image={projectImages.featherfell.main} handleClick={() => navigate("/projects/featherfell")}/>
        <Thumbnail image={projectImages.fish.main} handleClick={() => navigate("/projects/five-stages-of-immigration")}/>
        <Thumbnail image={projectImages.aswang.main} handleClick={() => navigate("/projects/aswang")}/>
      </div>
    </>
  );
}

export default Projects;
