import React from "react";
import Gallery from "../components/Gallery";

import "./Home.css";

import { useEffect, useState, useMemo } from "react";

function Home() {
  const [screenWidth, setScreenWidth] = useState(
    window.innerWidth <= 800 ? null : window.innerWidth
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setScreenWidth(null);
        return;
      }
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scale = useMemo(() => screenWidth / 1920, [screenWidth]);

  return (
    <div
      className="home"
      style={{
        maxHeight: "100vh",
      }}
    >
      <Gallery
        style={{
          transformOrigin: "0 0",
          transform: scale ? `scale(${scale})` : "initial",
          fontSize: scale ? `${1 / scale}em` : "initial",
        }}
        rowConfig={screenWidth > 800 ? [3, 3, 5, 6] : []}
      />
    </div>
  );
}

export default Home;
