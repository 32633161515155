// @ts-nocheck
import React, { useState } from "react";

import "./Contact.css";
import "./About.css";
import { Input, Textarea, Button } from "@chakra-ui/react";

const Contact = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleSubmit = () => {
    setHasSubmitted(true);
  };

  return (
    <div className="contact">
      <h2>Contact Me</h2>
      <form
        action="https://docs.google.com/forms/d/e/1FAIpQLSfobTv5iQ3_2nw-UjeWaupquXpnEl7Xj77dczkoLr0qKRGIag/formResponse"
        encType="text/plain"
        onSubmit={handleSubmit}
        target="hidden_iframe"
      >
        <Input
          placeholder="What's your email address?"
          required
          type="email"
          name="entry.53725656"
        />
          <Textarea
            placeholder="Tell me anything!"
            required
            rows={4}
            type="text"
            name="entry.1557499429"
          />
        <Button
          className="contact-submit"
          type="submit"
          style={{ margin: "20px 0", alignSelf: "flex-end" }}
        >
          Submit
        </Button>
      </form>
      {
      hasSubmitted ? (
        <p className="thankyou">Thanks for reaching out! Looking forward to working with you</p>
      ) : null}
      <iframe
        id="hidden_iframe"
        title="Contact form"
        name="hidden_iframe"
        style={{ display: "none" }}
      ></iframe>
    </div>
  );
};

export default Contact;
