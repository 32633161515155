import "./GalleryDesktop.css";
import "./GalleryMobile.css";
import "./GalleryShared.css";
import { useEffect, useRef, useState, useCallback, useMemo } from "react";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Modal from "./Modal";
import React from "react";
import { defaultImages } from "./galleryImages";

const LEFT = 37;
const RIGHT = 39;
const offset = 1;

const Gallery = ({ galleryImages, type, rowConfig = [], style = {} }) => {
  const images = (galleryImages || defaultImages).map((rest, index) => ({
    ...rest,
    id: index + 1,
  }));

  const [selected, setSelected] = useState(undefined);
  const [newImages, setNewImages] = useState(images);

  const selectedRef = useRef();

  const previousImage = useMemo(() => {
    return images[selected?.id - 1 - offset];
  }, [images, selected?.id]);

  const nextImage = useMemo(() => {
    return images[selected?.id + 1 - offset];
  }, [images, selected?.id]);

  const onBack = useCallback(() => {
    if (!previousImage) {
      return;
    }
    setSelected(previousImage);
  }, [previousImage]);

  const onNext = useCallback(() => {
    if (!nextImage) {
      return;
    }
    setSelected(nextImage);
  }, [nextImage]);

  useEffect(() => {
    const handleLeft = (event) => {
      if (event.keyCode === LEFT) {
        onBack();
      }
    };

    const handleRight = (event) => {
      if (event.keyCode === RIGHT) {
        onNext();
      }
    };

    window.addEventListener("keydown", handleLeft);
    window.addEventListener("keydown", handleRight);

    return () => {
      window.removeEventListener("keydown", handleLeft);
      window.removeEventListener("keydown", handleRight);
    };
  }, [onNext, onBack, selected, setSelected]);

  const handleClick = (image, e) => {
    selectedRef.current = e.target;

    setSelected(image);
  };

  useEffect(() => {
    const clone = [...images];
    images.forEach((image, index) => {
      const img = new Image();
      img.src = image.lowRes;
      img.onload = () => {
        clone[index].src = image.lowRes;
        setNewImages([...clone]);
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRow = (images) => {
    return images.map((image) => {
      return (
        <div
          id={image.name}
          key={image.id}
          onClick={(e) => handleClick(image, e)}
          className={`container ${selected === image.name ? "active" : ""}`}
        >
          <div
            className={`img ${image.src !== image.thumbnail ? "loaded" : ""}`}
          >
            <img
              className={`img ${image.src !== image.thumbnail ? "loaded" : ""}`}
              alt={image.title}
              id={image.name}
              src={image.src}
            />
            <div id={image.name} className="overlay">
              <p id={image.name}>{image.title}</p>
            </div>
          </div>
        </div>
      );
    });
  };

  const getGalleryImagesPerRow = () => {
    let listIndex = 0;
    const rows = rowConfig.reduce((acc, count, index) => {
      for (let i = 0; i < count; i++, listIndex++) {
        acc[index] = [...(acc[index] || []), newImages[listIndex]];
      }

      return acc;
    }, []);

    return rows;
  };

  const getGalleryRows = () => {
    const rows = getGalleryImagesPerRow();
    return rows.map((row, index) => {
      return <div key={index}>{setRow(row)}</div>;
    });
  };

  return (
    <>
      <Modal
        onBack={onBack}
        onNext={onNext}
        isFirst={!previousImage}
        isLast={!nextImage}
        selected={selected}
        deselect={() => setSelected(undefined)}
      />
      <div
        id="gallery"
        className={`gallery ${type ? "gallery-" + type : "gallery-main"}`}
        style={style}
      >
        {rowConfig.length ? getGalleryRows() : setRow(newImages)}
      </div>
    </>
  );
};

export default Gallery;
