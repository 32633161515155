import Chapter1final from "../../images/projects/fish/Chapter1final.jpg";
import Chapter2final from "../../images/projects/fish/Chapter2final.jpg";
import Chapter3final from "../../images/projects/fish/Chapter3final.jpg";
import Chapter4final from "../../images/projects/fish/Chapter4final.jpg";
import Chapter5final from "../../images/projects/fish/Chapter5final.jpg";

const fish = {
  main: {
    name: 'fish_main',
    src: require('../../images/projects/fish_main.jpg'),
    title: "5 Stages of Immigration",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam"
  },
  banner: {
    name: 'Chapter4final',
    src: require('../../images/projects/fish/fishbanner.jpg'),
    title: "5 Stages of Immigration",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam"
  },
  first: {
    name: 'Chapter1final',
    src: Chapter1final,
    title: "Fish Man Island",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam"
  },
  second: {
    name: 'Chapter2final',
    src: Chapter2final,
    title: "Fish Man Island",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam"
  },
  third: {
    name: 'Chapter3final',
    src: Chapter3final,
    title: "Fish Man Island",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam"
  },
  fourth: {
    name: 'Chapter4final',
    src: Chapter4final,
    title: "Fish Man Island",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam"
  },
  fifth: {
    name: 'Chapter5final',
    src: Chapter5final,
    title: "Fish Man Island",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam"
  },
}

const trailer = {
  t1: require('../../images/projects/featherfell/trailer1final_project.jpg'),
  t2: require('../../images/projects/featherfell/trailer2final_project.jpg'),
  t3: require('../../images/projects/featherfell/trailer3final_project.jpg'),
  t4: require('../../images/projects/featherfell/trailer4_project.jpg'),
  t5: require('../../images/projects/featherfell/trailer5_project.jpg'),
}

const artbook = {
  pg2: require('../../images/projects/featherfell/artbook/artbookupdated-2.png'),
  pg3: require('../../images/projects/featherfell/artbook/artbookupdated-3.png'),
  pg4: require('../../images/projects/featherfell/artbook/artbookupdated-4.png'),
  pg5: require('../../images/projects/featherfell/artbook/artbookupdated-5.png'),
  pg6: require('../../images/projects/featherfell/artbook/artbookupdated-6.png'),
  pg7: require('../../images/projects/featherfell/artbook/artbookupdated-7.png'),
  pg8: require('../../images/projects/featherfell/artbook/artbookupdated-8.png'),
  pg9: require('../../images/projects/featherfell/artbook/artbookupdated-9.png'),
  pg10: require('../../images/projects/featherfell/artbook/artbookupdated-10.png'),
  pg11: require('../../images/projects/featherfell/artbook/artbookupdated-11.png'),
  pg12: require('../../images/projects/featherfell/artbook/artbookupdated-12.png'),
  pg13: require('../../images/projects/featherfell/artbook/artbookupdated-13.png'),
  pg14: require('../../images/projects/featherfell/artbook/artbookupdated-14.png'),
  pg15: require('../../images/projects/featherfell/artbook/artbookupdated-15.png'),
  pg16: require('../../images/projects/featherfell/artbook/artbookupdated-16.png'),
  pg17: require('../../images/projects/featherfell/artbook/artbookupdated-17.png'),
  pg18: require('../../images/projects/featherfell/artbook/artbookupdated-18.png'),
  pg19: require('../../images/projects/featherfell/artbook/artbookupdated-19.png'),
  pg20: require('../../images/projects/featherfell/artbook/artbookupdated-20.png'),
  pg21: require('../../images/projects/featherfell/artbook/artbookupdated-21.png'),
  pg22: require('../../images/projects/featherfell/artbook/artbookupdated-22.png'),
  pg23: require('../../images/projects/featherfell/artbook/artbookupdated-23.png'),
  pg24: require('../../images/projects/featherfell/artbook/artbookupdated-24.png'),
  pg25: require('../../images/projects/featherfell/artbook/artbookupdated-25.png'),
  pg26: require('../../images/projects/featherfell/artbook/artbookupdated-26.png'),
  pg27: require('../../images/projects/featherfell/artbook/artbookupdated-27.png'),
  pg28: require('../../images/projects/featherfell/artbook/artbookupdated-28.png'),
  pg29: require('../../images/projects/featherfell/artbook/artbookupdated-29.png'),
  pg30: require('../../images/projects/featherfell/artbook/artbookupdated-30.png'),
  pg31: require('../../images/projects/featherfell/artbook/artbookupdated-31.png'),
  pg32: require('../../images/projects/featherfell/artbook/artbookupdated-32.png'),
  pg33: require('../../images/projects/featherfell/artbook/artbookupdated-33.png'),
  pg34: require('../../images/projects/featherfell/artbook/artbookupdated-34.png'),
  pg35: require('../../images/projects/featherfell/artbook/artbookupdated-35.png'),
  pg36: require('../../images/projects/featherfell/artbook/artbookupdated-36.png'),
}

const featherfell = {
  main: {
    name: 'featherfell_main',
    src: require('../../images/projects/featherfell_main.png'),
    title: "Feather Fell",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
  },
  banner: {
    name: 'trailer4.jpg',
    src: require('../../images/projects/featherfell/trailer4.jpg'),
    title: "Feather Fell",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
  },
  gamecoverfinal: {
    name: 'gamecoverfinal.png',
    src: require('../../images/projects/featherfell/gamecoverfinal.png'),
    title: "Game cover",
  },
  cover: require('../../images/projects/featherfell/artbook/artbookfinal.jpg'),
  trailer,
  artbook
}


const aswang = {
  main: {
    name: 'aswang_main',
    src: require('../../images/projects/aswang_main.jpg'),
    title: "Aswang",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
  },
  banner: {
    name: 'aswang_banner',
    src: require('../../images/projects/aswangbanner.jpg'),
    title: "Feather Fell",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
  },
  tiktik: {
    name: 'tiktik',
    src: require('../../images/T3.jpg'),
    title: "Tiktik",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
  },
  tikbalang: {
    name: 'tikbalang',
    src: require('../../images/T2.jpg'),
    title: "Feather Fell",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
  },
  sigbin: {
    name: 'sigbin',
    src: require('../../images/T1.jpg'),
    title: "Feather Fell",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
  },
  manananggal: {
    name: 'manananggal',
    src: require('../../images/T4.jpg'),
    title: "Feather Fell",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
  },
}

const images = {
  featherfell,
  fish,
  aswang
};

export { images as projectImages}
