import React from "react";
import { projectImages } from "./projectImages";

import "./Projects.css";

function Aswang() {
  const title = "Aswang";
  const intro =
    "This project is a personal challenge for myself to illustrate found-footage horror imagery in hopes to overcome my extreme fear of the dark and all things supernatural. The creatures featured in the series are from Philippine folklore and are the very same ones my parents used to scare me with growing up.";

  const banner = (
    <img
      style={{ width: "100%", opacity: "75%" }}
      src={projectImages.aswang.banner.src}
      alt=""
    />
  );

  return (
    <div className="projects">
      <div className="banner"> {banner} </div>

      <div className="aswang-container">
        <div
          style={{
            maxWidth: "1600px",
            padding: "60px",
            display: "flex",
            gap: "60px",
            margin: "0 auto",
          }}
        >
          <div>
            <h1>{title}</h1>
            <p> {intro} </p>
          </div>
        </div>
        <div className="aswang-gallery">
          <div className="aswang-gallery-section">
            <img src={projectImages.aswang.tikbalang.src} alt="" />
            <p>
            <h2>Kapre</h2>

              Kapre Tree dwelling giant who occasionally messes with civillians
            </p>
          </div>

          <div className="aswang-gallery-section">
            <p>
              <h2>Tiktik</h2>
              Tiktik Creature that has a long tongue that suck babies from
              pregnant wombs
            </p>
            <img src={projectImages.aswang.tiktik.src} alt="" />
          </div>

          <div className="aswang-gallery-section">
            <img src={projectImages.aswang.sigbin.src} alt="" />
            <p>
              <h2>Sigbin</h2>
              Sigbin Goat creature that walks backwards with its head lowered
              between its hind legs
            </p>
          </div>

          <div className="aswang-gallery-section">
            <p>
              <h2>Manananggal</h2>
              Manananggal Aswang lady that looks normal until she bisects from
              her waist to take flight and hunt
            </p>
            <img src={projectImages.aswang.manananggal.src} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aswang;
