// @ts-nocheck
import React from "react";
import { projectImages } from "./projectImages";

import "./Projects.css";

function FeatherFell() {
  const intro =
    "Featherfell is a dark fantasy game concept about a group of wingless birds who were corrupted by prophecy in a quest to obtain the eyes of God to gain its power. But in the God’s last moments, it cursed the land by devouring the sun and becoming a never-ending eclipse that melts the wings of all who wish to fly. The main character Icarus, whose freedom was taken away from her, dedicates her life to blinding the sun and its remaining eyes.";

  const banner = (
    <img
      style={{ width: "100%", opacity: "75%" }}
      src={projectImages.featherfell.banner.src}
      alt=""
    />
  );

  return (
    <div className="projects">
      <div className="banner"> {banner} </div>
      <div
        style={{
          maxWidth: "1800px",
          padding: "60px",
          display: "flex",
          alignItems: 'center',
          gap: "60px",
          margin: "0 auto",
        }}
      >
        <div>
          <h1>FeatherFell</h1>
          <p> {intro} </p>
        </div>
        <img style={{ maxWidth: '850px'}} src={projectImages.featherfell.gamecoverfinal.src} alt="" />
      </div>
      <div className="featherfell-container">
        <iframe
          src="https://player.vimeo.com/video/820203781?h=7f9f3d1add"
          width="100%"
          height="600"
          frameborder="0"
          title="Trailer"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>

        <div className="trailer">
          <img
            id="a"
            style={{ gridArea: "a" }}
            src={projectImages.featherfell.trailer.t1}
            alt=""
          />
          <img
            id="b"
            style={{ gridArea: "b" }}
            src={projectImages.featherfell.trailer.t2}
            alt=""
          />
          <img
            id="c"
            style={{ gridArea: "c" }}
            src={projectImages.featherfell.trailer.t3}
            alt=""
          />
          <img
            id="e"
            style={{ gridArea: "e" }}
            src={projectImages.featherfell.trailer.t5}
            alt=""
          />
          <img
            id="d"
            style={{ gridArea: "d" }}
            src={projectImages.featherfell.trailer.t4}
            alt=""
          />
        </div>

        <img src={projectImages.featherfell.cover} alt="" />

        <div>
          {Object.entries(projectImages.featherfell.artbook).map(
            ([key, value]) => {
              return <img src={value} alt="" />;
            }
          )}
        </div>
      </div>
    </div>
  );
}

export default FeatherFell;
