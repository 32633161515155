import React from 'react'
import { FaArtstation } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
// import { RiInstagramFill, RiLinkedinFill } from "react-icons/ri";
import { RiInstagramFill, RiLinkedinFill } from "react-icons/ri";
import './SocialMedia.css'

const SocialMedia = ({ size = 1}) => {
  const width = 1.5;
  const font = 1.2 * size;

  return (
    <div className="social-media-container" style={{ display: "flex", flexWrap: 'wrap' }}>
      <a href="mailto:johnbernard75g@gmail.com">
        <MdEmail
          style={{ width: `${width}em`, fontSize: `${font}em`  }}
        />
      </a>
      <a
        href="https://www.linkedin.com/in/john-gallego-90583822b/"
        target="_blank"
        rel="noreferrer"
      >
        <RiLinkedinFill
          style={{ width: `${width}em`, fontSize: `${font}em` }}
        />
      </a>
      <a
        href="https://www.instagram.com/jb_gelato/?hl=en"
        target="_blank"
        rel="noreferrer"
      >
        <RiInstagramFill
          style={{ width: `${width}em`, fontSize: `${font}em` }}
        />
      </a>
      <a
        href="https://www.artstation.com/gelatochanart"
        target="_blank"
        rel="noreferrer"
      >
        <FaArtstation
          style={{ width: `${width}em`, fontSize: `${font}em` }}
        />
      </a>
    </div>
  );
};

export default SocialMedia;
