// @ts-nocheck
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Cats from "./pages/Cats";
import SocialMedia from "./components/SocialMedia";
import ThemeToggle from "./components/ThemeToggle";

import "./App.css";
import "./AppMobile.css";
import {
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import { RiMenuUnfoldLine } from "react-icons/ri";
// import Hero from "./components/Hero";
import Projects from "./components/Projects";
import FeatherFell from "./components/Projects/FeatherFell";
import Fish from "./components/Projects/Fish";
import Aswang from "./components/Projects/Aswang";
// import useRunOnce from "./useRunOnce"

export default function BasicExample() {
  const [navVisible, setNavVisible] = useState(false);
  // const [fakeLoading, setFakeLoading] = useState(false);
  const [isProjectsActive, setIsProjectsActive] = useState(false);

  const config = {
    initialColorMode: "dark",
    useSystemColorMode: false,
  };
  const colors = {
    gray: {
      800: '#121212',
    },
  }

  const theme = extendTheme({ config, colors });

  // useRunOnce({
  //   fn: () => {
  //     setFakeLoading(true)
  //     localStorage.setItem('analytics-has-been-sent', 'true')
  //   },
  //   sessionKey: "jb_portfolio"
  // });

  // useEffect(() => {
  //   setTimeout(() => {
  //     setFakeLoading(false)
  //   }, 3000)
  // }, [])

  return (
    <ChakraProvider theme={theme}>
      <div className={`main-layout ${!navVisible ? "nav-closed" : "nav-open"}`}>
        <Router>
          {navVisible ? (
            <div
              onClick={() => setNavVisible(false)}
              style={{
                height: "100%",
                width: "100%",
                position: "fixed",
                zIndex: 1,
              }}
            />
          ) : null}

          <div className="menu" onClick={() => setNavVisible((prev) => !prev)}>
            <RiMenuUnfoldLine />
          </div>
          <div className="nav">
            <ul
              style={{
                display: "flex",
                listStyleType: "none",
              }}
            >
              <li
                onClick={() => setNavVisible(false)}
                style={{ marginRight: "20px" }}
              >
                <Link to="/works">Works</Link>
              </li>
              <li style={{ marginRight: "20px" }}>
                <Link
                  to="/projects"
                  onMouseOver={() => setIsProjectsActive(true)}
                  onMouseOut={() => setIsProjectsActive(false)}
                >
                  Projects
                </Link>
                <ul className={isProjectsActive ? "active" : ""}>
                  <li>
                    <Link to="/projects/featherfell">Featherfell</Link>
                  </li>
                  <li>
                    <Link to="/projects/five-stages-of-immigration">
                      The 5 Stages of Immigration
                    </Link>
                  </li>
                  <li>
                    <Link to="/projects/aswang">Aswang</Link>
                  </li>
                </ul>
              </li>
              <li
                onClick={() => setNavVisible(false)}
                style={{ marginRight: "20px" }}
              >
                <Link to="/cats">Cats</Link>
              </li>
              <li
                onClick={() => setNavVisible(false)}
                style={{ marginRight: "20px" }}
              >
                <Link to="/about">About & Contact</Link>
              </li>
            </ul>
            <div className="nav-other-options-container">
              <SocialMedia />
              <ThemeToggle />
            </div>
          </div>

          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/works" element={<Home />}></Route>
            <Route path="/projects" element={<Projects />}></Route>
            <Route
              path="/projects/featherfell"
              element={<FeatherFell />}
            ></Route>
            <Route
              path="/projects/five-stages-of-immigration"
              element={<Fish />}
            ></Route>
            <Route path="/projects/aswang" element={<Aswang />}></Route>
            <Route path="/cats" element={<Cats />}></Route>
            <Route path="/about" element={<About />}></Route>
          </Routes>
        </Router>
      </div>
    </ChakraProvider>
  );
}
