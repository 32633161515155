// @ts-nocheck
import Gallery from "../components/Gallery.js";
import { cats } from "../components/galleryImages.js";

function Cats() {
	return (
		<div className="cats-container">
			<Gallery galleryImages={cats} type={"cats"}></Gallery>
		</div>
	);
}

export default Cats;
