import React from "react";
import { projectImages } from "./projectImages";

import "./Projects.css";

function Fish() {
  const intro =
    "The 5 Stages of Immigration tells the story and emotions of anyone who’s had to move places at any point of their lives. The series follows a fish person who moves into human society as he struggles with the ups and downs of culture shock.";

  const banner = (
    <img
      style={{ width: "100%", opacity: "75%" }}
      src={projectImages.fish.banner.src}
      alt=""
    />
  );

  return (
    <div className="projects">
      <div className="banner"> {banner} </div>
      <div className="fish-container">
        <div
          style={{
            maxWidth: "1600px",
            padding: "60px",
            display: "flex",
            gap: "60px",
            margin: "0 auto",
          }}
        >
          <div>
            <h1>The 5 Stages of Immigration</h1>
            <p> {intro} </p>
          </div>
        </div>
        <div className="fish-gallery">
          <img style={{ gridArea: "a"}} src={projectImages.fish.first.src} alt="" />
          <img style={{ gridArea: "b"}} src={projectImages.fish.second.src} alt="" />
          <img style={{ gridArea: "c"}} src={projectImages.fish.third.src} alt="" />
          <img style={{ gridArea: "d"}} src={projectImages.fish.fourth.src} alt="" />
          <span
          style={{ gridArea: "blank1" }}
          />
        <img
          style={{ gridArea: "e" }}
          src={projectImages.fish.fifth.src}
          alt=""
        />
          <span
          style={{ gridArea: "blank2" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Fish;
