// @ts-nocheck
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import profile from "../images/about.jpg";
import Contact from "./Contact.js";
import "./About.css";
import { Box, Button, useColorModeValue } from "@chakra-ui/react";
import { MdEmail } from "react-icons/md";

function About() {
  const location = useLocation();
  const bg = useColorModeValue("white", "gray.800");

  useEffect(() => {
    if (location.pathname === "/about") {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }, [location.pathname]);

  return (
    <div className="about-container">
      <img src={profile} alt="Porfile" className="profile-img" />
      <div className="about-info-section">
        <div className="about-info">
          <h2
            className="hi"
            style={{
              fontSize: "3em",
              lineHeight: "1em",
              fontWeight: "400",
              position: "absolute",
            }}
          >
            Hi!
          </h2>
          <h2 style={{ fontSize: "3em", lineHeight: "1em", fontWeight: "400" }}>
            Hi! My name is{" "}
            <span className="gold-text" style={{ fontWeight: "600" }}>
              JB
            </span>
          </h2>
          <p style={{ marginTop: "50px" }}>
            I am a Filipino-Canadian artist based in Guelph, Ontario, 
            who specializes in creating complex <b className="gold-text">illustrations</b> with a painterly feeling.
          </p>
          <p style={{ marginTop: "30px" }}>
            As a huge colour nerd, I am versatile in painting any mood and atmosphere. 
            My inspirations vary from dark fantasy to happy colourful stories. 
            My works strive to capture the impression of traditional painting into digital media, 
            combining classical painting techniques with modern concepts and technology.
          </p>
          <p style={{ marginTop: "30px" }}>
            When I’m not doing art, you can find me geeking about shows, 
            doing ungodly amounts of karaoke, and climbing some funny rocks at the gym.
          </p>
          <p style={{ marginTop: "30px" }}>
            <Button leftIcon={<MdEmail />} variant="solid">
              <a
                href="mailto:johnbernard75g@gmail.com"
                style={{ zIndex: 1, position: "relative" }}
              >
                johnbernard75g@gmail.com
              </a>
            </Button>
          </p>
        </div>
        <Contact />
        <Box className="about-overlay" bg={bg} />
      </div>
    </div>
  );
}

export default About;
