import React from 'react'

import "../GalleryShared.css";


const Thumbnail = ({image, handleClick}) => {
    return (<div
        id={image.name}
        key={image.id}
        onClick={(e) => handleClick(image, e)}
        className={`container reverse-color`}
        style={{ position: "relative", }}
    >
        <div
        className={`img loaded`}
        >
        <img
            className={`img loaded`}
            alt={image.title}
            id={image.name}
            src={image.src}
        />
        <div id={image.name} className="overlay">
            <p id={image.name}>{image.title}</p>
        </div>
        </div>
    </div>)
}

export default Thumbnail
